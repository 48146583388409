import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Store } from 'react-notifications-component';
import { getValidToken } from '../utils/tokenManager';
import InvocationLogs from './InvocationLogs';

function AutoInvocationTab({ agentId }) {
  const [invocationInterval, setInvocationInterval] = useState('');
  const [nextScheduledRun, setNextScheduledRun] = useState('');
  const [loading, setLoading] = useState(true);
  const [autoInvokeEnabled, setAutoInvokeEnabled] = useState(false);

  useEffect(() => {
    fetchSchedulingSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSchedulingSettings = async () => {
    setLoading(true);
    const token = await getValidToken();
    try {
      const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/schedule`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setInvocationInterval(response.data.invocationInterval || '');
      setNextScheduledRun(response.data.nextScheduledRun || '');
    } catch (error) {
      console.error('Error fetching scheduling settings:', error);
      Store.addNotification({
        title: "Error",
        message: "Failed to fetch scheduling settings.",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    const token = await getValidToken();
    try {
      await axios.put(`${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/schedule`, {
        invocationInterval: invocationInterval || '',
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      Store.addNotification({
        title: "Success",
        message: "Scheduling settings updated.",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      // Optionally refresh the next scheduled run
      fetchSchedulingSettings();
    } catch (error) {
      console.error('Error updating scheduling settings:', error);
      Store.addNotification({
        title: "Error",
        message: "Failed to update scheduling settings.",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  const handleToggleAutoInvoke = async () => {
    try {
      const token = await getValidToken();
      await axios.post(
        `${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/toggle-auto-invoke`,
        { enabled: !autoInvokeEnabled },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setAutoInvokeEnabled(!autoInvokeEnabled);

      Store.addNotification({
        title: "Success",
        message: `Auto Invocation ${!autoInvokeEnabled ? 'enabled' : 'disabled'} successfully.`,
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } catch (error) {
      console.error('Error toggling Auto Invocation:', error);
      Store.addNotification({
        title: "Error",
        message: "Failed to toggle Auto Invocation.",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  if (loading) {
    return <p>Loading scheduling settings...</p>;
  }

  return (
    <div>
      <h3 className="text-xl font-bold mb-4">Auto Invocation Settings</h3>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Enable Auto Invocation:
        </label>
        <div className="flex items-center">
          <label className="mr-6 flex items-center">
            <input
              type="radio"
              name="enableAutoInvocation"
              value=""
              checked={invocationInterval === ''}
              onChange={() => setInvocationInterval('')}
              className="mr-2"
            />
            Disabled
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              name="enableAutoInvocation"
              value="enabled"
              checked={invocationInterval !== ''}
              onChange={() => setInvocationInterval('daily')} // default to daily if enabling
              className="mr-2"
            />
            Enabled
          </label>
        </div>
      </div>

      {invocationInterval !== '' && (
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Invocation Frequency:
          </label>
          <select
            value={invocationInterval}
            onChange={(e) => setInvocationInterval(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="hourly">Hourly</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>
      )}

      {nextScheduledRun && invocationInterval !== '' && (
        <div className="mb-4">
          <p>
            <strong>Next Scheduled Run:</strong> {new Date(nextScheduledRun).toLocaleString()}
          </p>
        </div>
      )}

      <button
        onClick={handleSave}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Save Settings
      </button>

      <hr className="my-6" />

      <InvocationLogs agentId={agentId} />
    </div>
  );
}

export default AutoInvocationTab; 