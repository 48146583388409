import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaGithub, FaNpm, FaEye, FaEyeSlash, FaCopy, FaSync } from 'react-icons/fa';
import axios from 'axios';
import { getValidToken } from '../utils/tokenManager';
import { Store } from 'react-notifications-component';

const secretKey = `${process.env.REACT_APP_ABLY_SDK_KEY}`;

function Skills({user}) {
  const [showSecret, setShowSecret] = useState(false);
  const [activeTab, setActiveTab] = useState('available');
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const intervalRef = useRef(null);
  const initialFetchDone = useRef(false);
  const [listening, setListening] = useState(false);

  const fetchSkills = useCallback(async () => {
    if (!initialFetchDone.current) {
      console.log('Fetching skills...'); // Debug log
    }
    try {
      const token = await getValidToken();
      const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/getSkills`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSkills(response.data.skills);
      setLoading(false);
      if (!initialFetchDone.current) {
        initialFetchDone.current = true;
        console.log('Initial fetch completed'); // Debug log
      }
      setListening(true);
    } catch (error) {
      console.error('Error fetching skills:', error);
      Store.addNotification({
        title: "Error",
        message: "Failed to fetch skills. Please try again later.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    console.log({user})
    if (!initialFetchDone.current) {
      fetchSkills(); // Initial fetch
    }

    intervalRef.current = setInterval(fetchSkills, 10000); // Fetch every 10 seconds

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      setListening(false);
    }; // Clean up on unmount
  }, [user]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    // You might want to add a notification here to inform the user that the text has been copied
  };

  const renderSkillsTable = () => {
    return (
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="py-2 px-4 border-b">Name</th>
            <th className="py-2 px-4 border-b">Description</th>
            <th className="py-2 px-4 border-b">Parameters</th>
          </tr>
        </thead>
        <tbody>
          {skills.map((skill, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
              <td className="py-2 px-4 border-b">{skill.name}</td>
              <td className="py-2 px-4 border-b">{skill.description}</td>
              <td className="py-2 px-4 border-b">
                <strong>Properties:</strong><br />
                {Object.entries(skill.parameters.properties).map(([key, value]) => (
                  <div key={key}>
                    - {key} ({value.type}): {value.description}
                  </div>
                ))}
                <br />
                <strong>Required:</strong> {skill.parameters.required.join(', ')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-bold text-gray-800">Agent Skills</h1>
        <div className="flex items-center">
          <Link to="/agents" className="text-blue-500 hover:text-blue-600">Back to Agents</Link>
        </div>
      </div>
      
      <div className="mb-6">
        <div className="flex border-b">
          <button
            className={`py-2 px-4 ${activeTab === 'available' ? 'border-b-2 border-blue-500' : ''}`}
            onClick={() => setActiveTab('available')}
          >
            Skills Available
          </button>
          <button
            className={`py-2 px-4 ${activeTab === 'add' ? 'border-b-2 border-blue-500' : ''}`}
            onClick={() => setActiveTab('add')}
          >
            Add Skill
          </button>
        </div>
      </div>

      {activeTab === 'available' ? (
        <div>
          <p className="text-gray-600 mb-6">
            Here are the skills currently available for your agents:
          </p>
          {loading ? (
            <p>Loading skills...</p>
          ) : skills.length > 0 ? (
            renderSkillsTable()
          ) : (
            <div className="flex items-center justify-between">
              <p className="text-gray-600">No skills available yet. Add a new skill to get started!</p>
              {listening && (
                <div className="flex items-center text-green-600">
                  <FaSync className="animate-spin mr-2" />
                  <span>Listening for new skills...</span>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <p className="text-gray-600 mb-6">
            Enhance your agents with custom skills. Choose one of the following options to get started:
          </p>

          <div className="grid md:grid-cols-2 gap-6">
            <div className="border rounded-lg p-6 hover:shadow-lg transition-shadow">
              <h2 className="text-xl font-semibold mb-4 flex items-center">
                <FaNpm className="mr-2 text-red-500" /> Option 1: Use our SDK
              </h2>
              <p className="mb-4">
                Install our JavaScript SDK and code your own custom skill:
              </p>
              <pre className="bg-gray-100 p-2 rounded mb-4">
                npm install zapdroid-sdk
              </pre>
              <a 
                href="https://www.npmjs.com/package/zapdroid-sdk" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                View SDK Documentation
              </a>
            </div>

            <div className="border rounded-lg p-6 hover:shadow-lg transition-shadow">
              <h2 className="text-xl font-semibold mb-4 flex items-center">
                <FaGithub className="mr-2" /> Option 2: Start with a Template
              </h2>
              <p className="mb-4">
                Fork our starter skill repository and customize it:
              </p>
              <a 
                href="https://github.com/Zapdroid-io/skill-template" 
                target="_blank" 
                rel="noopener noreferrer"
                className="bg-gray-800 text-white px-4 py-2 rounded hover:bg-gray-700 transition-colors inline-flex items-center"
              >
                <FaGithub className="mr-2" /> Fork Starter Skill
              </a>
            </div>
          </div>

          <div className="mt-8 border rounded-lg p-6">
            <h2 className="text-2xl font-semibold mb-4">SDK Credentials</h2>
            <div className="space-y-4">
              <div className="flex items-center">
                <span className="font-semibold mr-2">User ID:</span>
                <span className="mr-2">{user.userId}</span>
                <button onClick={() => copyToClipboard(user.userId)} className="text-blue-500 hover:text-blue-600">
                  <FaCopy />
                </button>
              </div>
              <div className="flex items-center">
                <span className="font-semibold mr-2">ZAPDROID_SECRET_KEY:</span>
                <span className="mr-2">{showSecret ? secretKey : '•'.repeat(secretKey.length)}</span>
                <button onClick={() => setShowSecret(!showSecret)} className="mr-2 text-blue-500 hover:text-blue-600">
                  {showSecret ? <FaEyeSlash /> : <FaEye />}
                </button>
                <button onClick={() => copyToClipboard(secretKey)} className="text-blue-500 hover:text-blue-600">
                  <FaCopy />
                </button>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">Next Steps</h2>
            <ol className="list-decimal list-inside space-y-2">
              <li>Choose one of the options above to create your skill.</li>
              <li>Develop and test your skill locally.</li>
              <li>Once ready, deploy your skill to a hosting platform of your choice.</li>
              <li>Visit this page and you will see your skill in the list.</li>
            </ol>
          </div>

          <p className="mt-6 text-gray-600">
            Need help? Check out our <a href="#" className="text-blue-500 hover:underline">documentation</a> or <a href="#" className="text-blue-500 hover:underline">contact support</a>.
          </p>
        </div>
      )}
    </div>
  );
}

export default Skills;
