import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getValidToken } from '../utils/tokenManager';
import axios from 'axios';
import AgentFiles from './AgentFiles';
import { FaFolder, FaCode, FaComments, FaCog, FaEdit, FaBrain, FaTools } from 'react-icons/fa';
import { Store } from 'react-notifications-component';
import ModalComponent from './ModalComponent';
import EmbedTab from './EmbedTab';
import KnowledgeTab from './KnowledgeTab';
import SkillsToolsTab from './SkillsToolsTab';
import AutoInvocationTab from './AutoInvocationTab';

function AgentPage() {
  const { id } = useParams();
  const [agent, setAgent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('files');
  const [useCodeInterpreter, setUseCodeInterpreter] = useState(false);
  const [autoStartEnabled, setAutoStartEnabled] = useState(false);
  const [messagingPlatform, setMessagingPlatform] = useState('slack');
  const [timeout, setTimeout] = useState(60);
  const [prompt, setPrompt] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    async function fetchAgent() {
      try {
        const token = await getValidToken();
        const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/agents/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAgent(response.data);
        setUseCodeInterpreter(response.data.use_code_interpreter);
        setPrompt(response.data.prompt || '');
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch agent');
        setLoading(false);
      }
    }
    fetchAgent();
  }, [id]);

  const handleCodeInterpreterChange = async (value) => {
    try {
      const token = await getValidToken();
      const updatedAgent = {
        ...agent,
        use_code_interpreter: value
      };
      delete updatedAgent.userId;

      await axios.put(
        `${process.env.REACT_APP_USER_API_URL}/agents/${id}`,
        updatedAgent,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setUseCodeInterpreter(value);
      setAgent(updatedAgent);
    } catch (err) {
      console.error('Failed to update code interpreter setting:', err);
      Store.addNotification({
        title: "Error",
        message: "Failed to update code interpreter setting",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  };

  const handleAutoStartChange = (value) => {
    Store.addNotification({
      title: "Coming Soon!",
      message: "Auto-start feature will be available soon.",
      type: "info",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
    
    if (window.fathom) {
      window.fathom.trackGoal('AUTO_START_TOGGLED', 0);
    }
    
    setAutoStartEnabled(value);
  };

  const handlePromptChange = async () => {
    try {
      const token = await getValidToken();
      const updatedAgent = {
        ...agent,
        prompt
      };
      delete updatedAgent.userId;

      await axios.put(
        `${process.env.REACT_APP_USER_API_URL}/agents/${id}`,
        updatedAgent,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setAgent(updatedAgent);
      setIsModalOpen(false);
      Store.addNotification({
        title: "Success",
        message: "Prompt updated successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    } catch (err) {
      console.error('Failed to update prompt:', err);
      Store.addNotification({
        title: "Error",
        message: "Failed to update prompt",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  };

  if (loading) return <div>Loading agent...</div>;
  if (error) return <div>{error}</div>;
  if (!agent) return <div>Agent not found</div>;

  const chatUrl = `${process.env.REACT_APP_ASK_ZAPDROID_URL}?agentId=${id}`;

  const handlePublish = async (path) => {
    try {
      const token = await getValidToken();
      const response = await axios.post(
        `${process.env.REACT_APP_USER_API_URL}/publisher/publish`,
        { agentId: agent.id, path },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      console.log('Directory published:', response.data);
      Store.addNotification({
        title: "Success",
        message: "Directory published successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    } catch (error) {
      console.error('Error publishing directory:', error);
      Store.addNotification({
        title: "Error",
        message: "Failed to publish directory",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white shadow rounded-lg p-6">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-bold text-gray-800">{agent.name}</h1>
          <a
            href={chatUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <FaComments className="mr-2" />
            Chat with agent
          </a>
        </div>
        <div className="mb-6 relative group">
          <h2 className="text-xl font-semibold text-gray-700 mb-2">Prompt:</h2>
          <p className="text-gray-600">{prompt || 'No prompt set'}</p>
          <FaEdit
            className="absolute top-0 right-0 text-gray-500 cursor-pointer opacity-0 group-hover:opacity-100"
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      </div>

      <ModalComponent
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Edit Prompt"
      >
        <textarea
          className="w-full p-2 border border-gray-300 rounded"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
        />
        <div className="flex justify-end mt-4">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handlePromptChange}
          >
            Save
          </button>
        </div>
      </ModalComponent>

      <div className="bg-white shadow rounded-lg p-6">
        <div className="mb-4 flex">
          <button
            className={`mr-2 px-4 py-2 rounded-t-lg flex items-center ${
              activeTab === 'files' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setActiveTab('files')}
          >
            <FaFolder className="mr-2" />
            Files
          </button>
          <button
            className={`mr-2 px-4 py-2 rounded-t-lg flex items-center ${
              activeTab === 'embed' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setActiveTab('embed')}
          >
            <FaCode className="mr-2" />
            Embed
          </button>
          <button
            className={`mr-2 px-4 py-2 rounded-t-lg flex items-center ${
              activeTab === 'knowledge' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setActiveTab('knowledge')}
          >
            <FaBrain className="mr-2" />
            Knowledge
          </button>
          <button
            className={`mr-2 px-4 py-2 rounded-t-lg flex items-center ${
              activeTab === 'skills' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setActiveTab('skills')}
          >
            <FaTools className="mr-2" />
            Skills / Tools
          </button>
          <button
            className={`mr-2 px-4 py-2 rounded-t-lg flex items-center ${
              activeTab === 'auto-invocation' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setActiveTab('auto-invocation')}
          >
            <FaBrain className="mr-2" />
            Auto Invocation
          </button>
          <button
            className={`px-4 py-2 rounded-t-lg flex items-center ${
              activeTab === 'settings' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setActiveTab('settings')}
          >
            <FaCog className="mr-2" />
            Settings
          </button>
        </div>

        <div className="border-t border-gray-200 pt-4">
          {activeTab === 'files' && <AgentFiles agentId={id} />}
          {activeTab === 'embed' && <EmbedTab />}
          {activeTab === 'knowledge' && <KnowledgeTab agentId={id} />}
          {activeTab === 'skills' && <SkillsToolsTab agentId={id} />}
          {activeTab === 'auto-invocation' && <AutoInvocationTab agentId={id} />}
          {activeTab === 'settings' && (
            <div>
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Agent Settings</h2>
              <div className="space-y-6">
                <div className="flex items-center space-x-4">
                  <span className="text-sm font-medium text-gray-900">Use Code Interpreter</span>
                  <label className="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={useCodeInterpreter}
                      onChange={() => handleCodeInterpreterChange(!useCodeInterpreter)}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>

                <div className="space-y-4">
                  <h3 className="text-lg font-semibold text-gray-800">Auto-start agent</h3>
                  <div className="flex items-center space-x-4">
                    <span className="text-sm font-medium text-gray-900">Enable</span>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={autoStartEnabled}
                        onChange={() => handleAutoStartChange(!autoStartEnabled)}
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                  
                  {autoStartEnabled && (
                    <>
                      <div className="flex items-center space-x-4">
                        <span className="text-sm font-medium text-gray-900">Messaging Platform</span>
                        <select
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                          value={messagingPlatform}
                          onChange={(e) => setMessagingPlatform(e.target.value)}
                        >
                          <option value="slack">Slack</option>
                          <option value="telegram">Telegram</option>
                        </select>
                      </div>
                      <div className="flex items-center space-x-4">
                        <span className="text-sm font-medium text-gray-900">Timeout (minutes)</span>
                        <input
                          type="number"
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                          value={timeout}
                          onChange={(e) => setTimeout(e.target.value)}
                          min="1"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AgentPage;
