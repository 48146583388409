import React, { useState } from 'react';
import { FaChrome, FaSlack, FaTable } from 'react-icons/fa';
import ChromeExtension from './ChromeExtension';
import Slack from './Slack';
import Spreadsheets from './Spreadsheets';

function Apps() {
  const [activeTab, setActiveTab] = useState('chrome');

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">Apps</h1>
      <div className="mb-4 flex">
        <button
          className={`mr-2 px-4 py-2 rounded-t-lg flex items-center ${
            activeTab === 'chrome' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setActiveTab('chrome')}
        >
          <FaChrome className="mr-2" />
          Chrome Extension
        </button>
        <button
          className={`mr-2 px-4 py-2 rounded-t-lg flex items-center ${
            activeTab === 'slack' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setActiveTab('slack')}
        >
          <FaSlack className="mr-2" />
          Slack
        </button>
        <button
          className={`px-4 py-2 rounded-t-lg flex items-center ${
            activeTab === 'spreadsheets' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setActiveTab('spreadsheets')}
        >
          <FaTable className="mr-2" />
          Spreadsheets
        </button>
      </div>
      <div className="border-t border-gray-200 pt-4">
        {activeTab === 'chrome' ? <ChromeExtension /> : 
         activeTab === 'slack' ? <Slack /> : <Spreadsheets />}
      </div>
    </div>
  );
}

export default Apps;