import React, { useState, useEffect, useCallback } from 'react';
import { FaCode, FaCopy } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { getValidToken } from '../utils/tokenManager';
import { debounce } from 'lodash';

function EmbedTab() {
  const { id } = useParams();
  const [widgetEnabled, setWidgetEnabled] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    fetchWidgetSettings();
  }, []);

  const fetchWidgetSettings = async () => {
    try {
      const token = await getValidToken();
      const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/agents/${id}/widgetSettings`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { enabled, welcomeMsg } = response.data;
      setWidgetEnabled(enabled);
      setWelcomeMessage(welcomeMsg || '');
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching widget settings:', error);
      setIsLoading(false);
    }
  };

  const handleWidgetToggle = async () => {
    try {
      const token = await getValidToken();
      await axios.post(`${process.env.REACT_APP_USER_API_URL}/agents/${id}/widgetSettings`, {
        enabled: !widgetEnabled,
        welcomeMsg: welcomeMessage,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setWidgetEnabled(!widgetEnabled);
    } catch (error) {
      console.error('Error updating widget settings:', error);
    }
  };

  const debouncedUpdateWelcomeMessage = useCallback(
    debounce(async (message) => {
      try {
        const token = await getValidToken();
        await axios.post(`${process.env.REACT_APP_USER_API_URL}/agents/${id}/widgetSettings`, {
          enabled: widgetEnabled,
          welcomeMsg: message,
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } catch (error) {
        console.error('Error updating welcome message:', error);
      }
    }, 500),
    [id, widgetEnabled]
  );

  const handleWelcomeMessageChange = (e) => {
    const newMessage = e.target.value;
    setWelcomeMessage(newMessage);
    debouncedUpdateWelcomeMessage(newMessage);
  };

  const embedCode = `<div id="zapdroidWidget" data-agent-id="${id}"></div>\n<script src="${process.env.REACT_APP_BASE_URL}/widget.js"></script>`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(embedCode).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-gray-800">Embed This Agent</h2>
      
      <div className="flex items-center space-x-2">
        <label className="inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={widgetEnabled}
            onChange={handleWidgetToggle}
          />
          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
            {widgetEnabled ? 'Widget Enabled' : 'Widget Disabled'}
          </span>
        </label>
      </div>

      <div>
        <label htmlFor="welcomeMessage" className="block text-sm font-medium text-gray-700">
          Welcome Message
        </label>
        <textarea
          id="welcomeMessage"
          rows="3"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-2"
          placeholder="Enter a welcome message for your widget"
          value={welcomeMessage}
          onChange={handleWelcomeMessageChange}
          disabled={!widgetEnabled}
        ></textarea>
      </div>

      {widgetEnabled && (
        <div className="bg-gray-100 p-4 rounded">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-semibold">Embed Code</h3>
            <button
              onClick={copyToClipboard}
              className="text-blue-500 hover:text-blue-600 focus:outline-none"
              title="Copy to clipboard"
            >
              {copySuccess ? 'Copied!' : <FaCopy />}
            </button>
          </div>
          <pre className="bg-white p-2 rounded border border-gray-300 overflow-x-auto">
            <code>{embedCode}</code>
          </pre>
        </div>
      )}

      <div>
        <Link 
          to={`/agents/${id}/widget_preview`}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          target="_blank"
        >
          Preview Widget
        </Link>
      </div>
    </div>
  );
}

export default EmbedTab;