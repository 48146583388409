import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getValidToken } from '../utils/tokenManager';

function InvocationLogs({ agentId }) {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLogs = async () => {
    setLoading(true);
    const token = await getValidToken();
    try {
      const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/invocation-logs`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLogs(response.data);
    } catch (error) {
      console.error('Error fetching invocation logs:', error);
      // Handle error, e.g., display notification
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading logs...</p>;
  }

  if (logs.length === 0) {
    return <p>No invocation logs available.</p>;
  }

  return (
    <div className="mt-6">
      <h3 className="text-xl font-bold mb-4">Invocation Logs</h3>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Timestamp</th>
            <th className="py-2 px-4 border-b">Status</th>
            <th className="py-2 px-4 border-b">Details</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log, index) => (
            <tr key={index}>
              <td className="py-2 px-4 border-b">{new Date(log.timestamp).toLocaleString()}</td>
              <td className="py-2 px-4 border-b">{log.status}</td>
              <td className="py-2 px-4 border-b">{log.details}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default InvocationLogs; 