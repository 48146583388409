import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getValidToken } from '../utils/tokenManager';

function Spreadsheets() {
  const [token, setToken] = useState('');
  const [copySuccess, setCopySuccess] = useState('');

  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await getValidToken();
      try {
        const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/get-spreadsheet-token`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setToken(response.data.token);
      } catch (error) {
        console.error('Error fetching spreadsheet token:', error);
      }
    };
    fetchToken();
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(token).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000);
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Spreadsheets Integration</h2>
      <p className="text-gray-600">
        Connect Google Sheets to Zapdroid. <br /> <a className="special" href="https://github.com/AbhimanyuG/zapdroid-spreadsheets" target="_blank" rel="noopener noreferrer">Instructions</a>
      </p>
      {token && (
        <div className="mt-4">
          <h3 className="text-xl font-semibold">Your Spreadsheet Token:</h3>
          <div className="relative">
            <pre className="bg-gray-100 p-2 rounded overflow-x-auto break-all">
              {token}
            </pre>
            <button
              className="absolute top-2 right-2 bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
              onClick={copyToClipboard}
            >
              Copy
            </button>
          </div>
          {copySuccess && <p className="text-green-500 mt-2">{copySuccess}</p>}
        </div>
      )}
    </div>
  );
}

export default Spreadsheets; 