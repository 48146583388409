import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getValidToken } from '../utils/tokenManager';
import { FaSync } from 'react-icons/fa';
import { Store } from 'react-notifications-component';

function SkillsToolsTab({ agentId }) {
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [associatedSkills, setAssociatedSkills] = useState([]);

  useEffect(() => {
    fetchSkills();
    fetchAssociatedSkills();
  }, [agentId]);

  const fetchSkills = async () => {
    try {
      const token = await getValidToken();
      const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/getSkills`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSkills(response.data.skills);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching skills:', error);
      Store.addNotification({
        title: "Error",
        message: "Failed to fetch skills",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: { duration: 5000, onScreen: true }
      });
    }
  };

  const fetchAssociatedSkills = async () => {
    try {
      const token = await getValidToken();
      const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/associatedSkills`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAssociatedSkills(response.data);
    } catch (error) {
      console.error('Error fetching associated skills:', error);
      Store.addNotification({
        title: "Error",
        message: "Failed to fetch associated skills",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: { duration: 5000, onScreen: true }
      });
    }
  };

  const handleSkillAssociation = async (skillName, isChecked) => {
    try {
      const token = await getValidToken();
      if (isChecked) {
        await axios.post(
          `${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/associatedSkills/${skillName}`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } else {
        await axios.delete(
          `${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/associatedSkills/${skillName}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }
      fetchAssociatedSkills();
      Store.addNotification({
        title: "Success",
        message: `Skill ${isChecked ? 'associated' : 'disassociated'} successfully`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: { duration: 5000, onScreen: true }
      });
    } catch (error) {
      console.error('Error updating skill association:', error);
      Store.addNotification({
        title: "Error",
        message: "Failed to update skill association",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: { duration: 5000, onScreen: true }
      });
    }
  };

  const renderSkillsTable = () => {
    return (
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="py-2 px-4 border-b">Associate</th>
            <th className="py-2 px-4 border-b">Name</th>
            <th className="py-2 px-4 border-b">Description</th>
            <th className="py-2 px-4 border-b">Parameters</th>
          </tr>
        </thead>
        <tbody>
          {skills.map((skill, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
              <td className="py-2 px-4 border-b">
                <input
                  type="checkbox"
                  checked={associatedSkills.includes(skill.name)}
                  onChange={(e) => handleSkillAssociation(skill.name, e.target.checked)}
                />
              </td>
              <td className="py-2 px-4 border-b">{skill.name}</td>
              <td className="py-2 px-4 border-b">{skill.description}</td>
              <td className="py-2 px-4 border-b">
                <strong>Properties:</strong><br />
                {Object.entries(skill.parameters.properties).map(([key, value]) => (
                  <div key={key}>
                    - {key} ({value.type}): {value.description}
                  </div>
                ))}
                <br />
                <strong>Required:</strong> {skill.parameters.required.join(', ')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  if (loading) return <div>Loading skills...</div>;

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Skills / Tools</h2>
      {skills.length > 0 ? (
        renderSkillsTable()
      ) : (
        <div className="flex items-center justify-between">
          <p className="text-gray-600">No skills available yet.</p>
          <div className="flex items-center text-green-600">
            <FaSync className="animate-spin mr-2" />
            <span>Fetching skills...</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default SkillsToolsTab;
