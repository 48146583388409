import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getValidToken } from '../utils/tokenManager';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { FaArrowLeft } from 'react-icons/fa';

function WidgetPreview() {
  const { agent_id } = useParams();
  const [agent, setAgent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchAgent() {
      try {
        const token = await getValidToken();
        const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/agents/${agent_id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAgent(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch agent');
        setLoading(false);
      }
    }
    fetchAgent();
  }, [agent_id]);

  useEffect(() => {
    if (agent) {
      const script = document.createElement('script');
      script.src = "http://localhost:9001/bundle.js";
      script.async = true;
      script.setAttribute('data-agent-id', agent_id);
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [agent, agent_id]);

  if (loading) return <div className="flex justify-center items-center h-screen">Loading widget preview...</div>;
  if (error) return <div className="flex justify-center items-center h-screen">{error}</div>;
  if (!agent) return <div className="flex justify-center items-center h-screen">Agent not found</div>;

  return (
    <div className="min-h-screen bg-gray-100">
      <Helmet>
        <meta
          http-equiv="Content-Security-Policy"
          content="script-src 'self' 'unsafe-eval' http://localhost:9001; worker-src 'self' blob:;"
        />
      </Helmet>
      <div className="container mx-auto px-4 py-8">

        <h1 className="text-3xl font-bold mb-6">Widget Preview for {agent.name}</h1>
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Widget Appearance</h2>
          <div className="border border-gray-300 rounded-lg p-4 mb-6">
            {/* This is where you would render the actual widget preview */}
            <p className="text-gray-600">Widget preview will be implemented here.</p>
          </div>
          <h2 className="text-xl font-semibold mb-4">Embed Code</h2>
          <pre className="bg-gray-100 p-4 rounded-lg overflow-x-auto">
            <code>
              {`<script src="${process.env.REACT_APP_WIDGET_URL}/widget.js" data-agent-id="${agent_id}"></script>`}
            </code>
          </pre>
        </div>
      </div>
      <div id="zpadroidWidget"></div>
      
    </div>
  );
}

export default WidgetPreview;