import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getValidToken } from '../utils/tokenManager';
import { Store } from 'react-notifications-component';
import { FaFile, FaLink, FaPlus, FaTrash, FaSpinner, FaDownload } from 'react-icons/fa';

function KnowledgeTab({ agentId }) {
  const [knowledgeBase, setKnowledgeBase] = useState(null);
  const [newItem, setNewItem] = useState({ type: 'file', content: '', metadata: {} });
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    fetchKnowledgeBase();
  }, [agentId]);

  const fetchKnowledgeBase = async () => {
    try {
      const token = await getValidToken();
      const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/knowledge`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setKnowledgeBase(response.data);
    } catch (error) {
      Store.addNotification({
        title: 'Error',
        message: 'Failed to fetch knowledge base',
        type: 'danger',
        container: 'top-right',
        dismiss: { duration: 3000 },
      });
    }
  };

  const addKnowledgeItem = async () => {
    try {
      setIsUploading(true);
      const token = await getValidToken();
      let data;

      if (newItem.type === 'file') {
        const formData = new FormData();
        formData.append('file', newItem.content);
        formData.append('type', newItem.type);
        formData.append('metadata', JSON.stringify(newItem.metadata));

        data = formData;
      } else {
        data = newItem;
      }

      await axios.post(`${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/knowledge`, data, {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': newItem.type === 'file' ? 'multipart/form-data' : 'application/json',
        },
      });

      fetchKnowledgeBase();
      setNewItem({ type: 'file', content: '', metadata: {} });
    } catch (error) {
      Store.addNotification({
        title: 'Error',
        message: 'Failed to add knowledge item',
        type: 'danger',
        container: 'top-right',
        dismiss: { duration: 3000 },
      });
    } finally {
      setIsUploading(false);
    }
  };

  const deleteKnowledgeItem = async (itemId) => {
    try {
      const token = await getValidToken();
      await axios.delete(`${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/knowledge/${itemId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchKnowledgeBase();
    } catch (error) {
      Store.addNotification({
        title: 'Error',
        message: 'Failed to delete knowledge item',
        type: 'danger',
        container: 'top-right',
        dismiss: { duration: 3000 },
      });
    }
  };

  const downloadFile = async (item) => {
    try {
      if (item.type !== 'file') {
        throw new Error('Only file-type items can be downloaded');
      }

      const token = await getValidToken();
      const response = await axios.get(
        `${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/knowledge/${item.id}/download`,
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob',
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', item.metadata.filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      Store.addNotification({
        title: 'Error',
        message: 'Failed to download file',
        type: 'danger',
        container: 'top-right',
        dismiss: { duration: 3000 },
      });
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">Knowledge Base</h2>
      {knowledgeBase && (
        <div>
          <h3 className="text-xl font-semibold">{knowledgeBase.name}</h3>
          <ul className="mt-4 space-y-4">
            {knowledgeBase.items.map((item) => (
              <li key={item.id} className="flex items-center justify-between bg-gray-100 p-4 rounded-lg">
                <div className="flex items-center">
                  {item.type === 'file' ? <FaFile className="mr-2" /> : <FaLink className="mr-2" />}
                  <span>{item.type === 'file' ? item.metadata.filename : item.metadata.url}</span>
                </div>
                <div className="flex items-center">
                  {item.type === 'file' && (
                    <button onClick={() => downloadFile(item)} className="text-blue-500 mr-4">
                      <FaDownload />
                    </button>
                  )}
                  <button onClick={() => deleteKnowledgeItem(item.id)} className="text-red-500">
                    <FaTrash />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="mt-6">
        <h3 className="text-xl font-semibold mb-4">Add New Knowledge Item</h3>
        <div className="space-y-4">
          <select
            value={newItem.type}
            onChange={(e) => setNewItem({ ...newItem, type: e.target.value, metadata: {} })}
            className="w-full p-2 border rounded"
          >
            <option value="file">File</option>
            <option value="url">URL</option>
          </select>
          {newItem.type === 'file' && (
            <input
              type="file"
              accept=".md,.pdf"
              onChange={(e) => {
                const file = e.target.files[0];
                setNewItem({
                  ...newItem,
                  content: file,
                  metadata: { filename: file.name, filetype: file.type },
                });
              }}
              className="w-full p-2 border rounded"
            />
          )}
          {newItem.type === 'url' && (
            <div className="space-y-2">
              <input
                type="url"
                placeholder="Enter URL"
                value={newItem.metadata.url || ''}
                onChange={(e) => setNewItem({ ...newItem, metadata: { ...newItem.metadata, url: e.target.value } })}
                className="w-full p-2 border rounded"
              />
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="recursive"
                  checked={newItem.metadata.recursive || false}
                  onChange={(e) => setNewItem({ ...newItem, metadata: { ...newItem.metadata, recursive: e.target.checked } })}
                  className="mr-2"
                />
                <label htmlFor="recursive">Recursive</label>
              </div>
              <input
                type="text"
                placeholder="Pattern (optional)"
                value={newItem.metadata.pattern || ''}
                onChange={(e) => setNewItem({ ...newItem, metadata: { ...newItem.metadata, pattern: e.target.value } })}
                className="w-full p-2 border rounded"
              />
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="periodic_refresh"
                  checked={newItem.metadata.periodic_refresh || false}
                  onChange={(e) => setNewItem({ ...newItem, metadata: { ...newItem.metadata, periodic_refresh: e.target.checked } })}
                  className="mr-2"
                />
                <label htmlFor="periodic_refresh">Periodic Refresh</label>
              </div>
            </div>
          )}
          <button 
            onClick={addKnowledgeItem} 
            className="bg-blue-500 text-white px-4 py-2 rounded flex items-center"
            disabled={isUploading}
          >
            {isUploading ? <FaSpinner className="animate-spin mr-2" /> : <FaPlus className="mr-2" />}
            {isUploading ? 'Uploading...' : 'Add Item'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default KnowledgeTab;
