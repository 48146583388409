import React, { useState } from 'react';

function VectorDatabaseIntegration() {
  const [serverUrl, setServerUrl] = useState('');
  const [apiKey, setApiKey] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Implement the logic to save the vector database configuration
    console.log('Vector Database configuration:', { serverUrl, apiKey });
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">Vector Database Integration</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="serverUrl" className="block text-sm font-medium text-gray-700">
            Server URL
          </label>
          <input
            type="url"
            id="serverUrl"
            value={serverUrl}
            onChange={(e) => setServerUrl(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="apiKey" className="block text-sm font-medium text-gray-700">
            API Key
          </label>
          <input
            type="password"
            id="apiKey"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Save Configuration
        </button>
      </form>
    </div>
  );
}

export default VectorDatabaseIntegration;
