import React, { useState, useEffect, useCallback } from 'react';
import { getValidToken } from '../utils/tokenManager';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { FaFolder, FaFile, FaArrowLeft, FaDownload, FaPlus, FaUpload, FaGlobe, FaCopy, FaEdit, FaEye, FaTrash, FaInfoCircle } from 'react-icons/fa';
import './Spinner.css';

function AgentFiles({ agentId }) {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [fileActions, setFileActions] = useState({});
  const [previewFile, setPreviewFile] = useState(null);
  const [newFolderName, setNewFolderName] = useState('');
  const [showNewFolderInput, setShowNewFolderInput] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [publishedSiteInfo, setPublishedSiteInfo] = useState(null);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileDescription, setFileDescription] = useState('');

  const fetchFiles = useCallback(async (path) => {
    setLoading(true);
    try {
      const token = await getValidToken();
      const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/files`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { path }
      });
      setFiles(response.data.files);
      setCurrentPath(response.data.currentPath);
    } catch (err) {
      setError('Failed to fetch files');
    } finally {
      setLoading(false);
    }
  }, [agentId]);

  useEffect(() => {
    fetchFiles(currentPath);
  }, [fetchFiles, currentPath]);

  async function downloadFile(filename) {
    // remove double //
    const cleanedFilename = filename.replace(/\/\//g, '/');
    // encode the filename to handle special characters
    //const encodedFilename = encodeURIComponent(cleanedFilename);
    try {
      setFileActions(prev => ({ ...prev, [filename]: { action: 'downloading', progress: 0 } }));
      const token = await getValidToken();
      const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/files/download`, {
        params: { path: cleanedFilename },
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setFileActions(prev => ({ ...prev, [filename]: { action: 'downloading', progress: percentCompleted } }));
        }
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error('Error downloading file:', err);
    } finally {
      setFileActions(prev => ({ ...prev, [filename]: null }));
    }
  }

  async function deleteFile(filename) {
    try {
      setFileActions(prev => ({ ...prev, [filename]: { action: 'deleting', progress: 0 } }));
      const token = await getValidToken();
      await axios.delete(`${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/files/${filename}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchFiles(currentPath);
    } catch (err) {
      console.error('Error deleting file:', err);
    } finally {
      setFileActions(prev => ({ ...prev, [filename]: null }));
    }
  }

  async function downloadAllFiles() {
    try {
      setFileActions(prev => ({ ...prev, all: { action: 'downloading', progress: 0 } }));
      const token = await getValidToken();
      const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/download`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setFileActions(prev => ({ ...prev, all: { action: 'downloading', progress: percentCompleted } }));
        }
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `agent_${agentId}_files.zip`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error('Error downloading all files:', err);
    } finally {
      setFileActions(prev => ({ ...prev, all: null }));
    }
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    const uploadTasks = acceptedFiles.map(file => {
      const relativePath = file.path || file.webkitRelativePath || file.name;
      return uploadFile(file, relativePath);
    });
    await Promise.all(uploadTasks);
    fetchFiles(currentPath);
  }, [fetchFiles, uploadFile]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  async function uploadFile(file, relativePath) {
    const formData = new FormData();
    formData.append('file', file);
    const fullPath = `${currentPath}${relativePath}`.replace(/^\/+/, '');
    formData.append('path', fullPath);

    try {
      setIsUploading(true);
      setUploadProgress(0);
      const token = await getValidToken();
      await axios.post(`${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/files`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      setIsUploading(false);
    } catch (err) {
      console.error('Error uploading file:', err);
      setIsUploading(false);
    }
  }

  async function handlePreviewFile(filename) {
    try {
      const token = await getValidToken();
      const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/preview/${filename}`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob'
      });
      const fileType = response.headers['content-type'];
      const url = window.URL.createObjectURL(new Blob([response.data], { type: fileType }));
      setPreviewFile({ name: filename, url, type: fileType });
    } catch (err) {
      console.error('Error previewing file:', err);
    }
  }

  function closePreview() {
    setPreviewFile(null);
  }

  async function createFolder() {
    if (!newFolderName.trim()) return;

    try {
      const token = await getValidToken();
      await axios.post(`${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/folders`, 
        { folderName: newFolderName },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNewFolderName('');
      setShowNewFolderInput(false);
      fetchFiles(currentPath);
    } catch (err) {
      console.error('Error creating folder:', err);
    }
  }

  function handleFolderClick(folderName) {
    setCurrentPath(prevPath => `${prevPath}${folderName}/`);
  }

  function handleBreadcrumbClick(index) {
    const newPath = currentPath.split('/').slice(0, index + 1).join('/') + '/';
    setCurrentPath(newPath);
  }

  function renderBreadcrumbs() {
    const parts = currentPath.split('/').filter(Boolean);
    return (
      <div className="flex items-center mb-2">
        <span 
          className="text-blue-500 cursor-pointer mr-2"
          onClick={() => setCurrentPath('')}
        >
          (root)
        </span>
        {parts.map((part, index) => (
          <React.Fragment key={index}>
            <span className="mx-2">&gt;</span>
            <span 
              className="text-blue-500 cursor-pointer"
              onClick={() => handleBreadcrumbClick(index)}
            >
              {part}
            </span>
          </React.Fragment>
        ))}
      </div>
    );
  }

  async function handlePublishClick(path) {
    try {
      const token = await getValidToken();
      let siteInfo;

      // First, try to get existing site info
      try {
        const siteInfoResponse = await axios.get(
          `${process.env.REACT_APP_USER_API_URL}/publisher/site-info`,
          {
            params: { agentId, path },
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        siteInfo = siteInfoResponse.data;
      } catch (error) {
        // If 404 is returned, assume the site is not published
        if (error.response && error.response.status === 404) {
          siteInfo = null;
        } else {
          throw error; // Re-throw other errors
        }
      }

      // If not published, publish it
      if (!siteInfo) {
        await axios.post(
          `${process.env.REACT_APP_USER_API_URL}/publisher/publish`,
          { agentId, path },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        // Get the site info again after publishing
        const newSiteInfoResponse = await axios.get(
          `${process.env.REACT_APP_USER_API_URL}/publisher/site-info`,
          {
            params: { agentId, path },
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        siteInfo = newSiteInfoResponse.data;
      }

      setPublishedSiteInfo(siteInfo);
      setShowPublishModal(true);

      // Refresh the file list to update the UI
      fetchFiles(currentPath);
    } catch (error) {
      console.error('Error publishing directory:', error);
      // You might want to show an error message to the user here
    }
  }

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(() => {
      // You can add a notification here if you want to inform the user that the text was copied
      console.log('Copied to clipboard');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  }

  function renderPublishModal() {
    if (!showPublishModal || !publishedSiteInfo) return null;

    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
          <div className="mt-3 text-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Published Site Information</h3>
            <div className="mt-2 px-7 py-3">
              <p className="text-sm text-gray-500">
                Your site has been published. You can access it at:
              </p>
              <div className="mt-4 flex items-center justify-center">
                <input
                  type="text"
                  value={publishedSiteInfo.fullURL}
                  readOnly
                  className="border rounded px-2 py-1 mr-2 w-full"
                />
                <button
                  onClick={() => copyToClipboard(publishedSiteInfo.fullURL)}
                  className="bg-blue-500 text-white px-2 py-1 rounded mr-2"
                  title="Copy URL"
                >
                  <FaCopy />
                </button>
                <a
                  href={publishedSiteInfo.fullURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-green-500 text-white px-2 py-1 rounded"
                  title="Visit Site"
                >
                  <FaGlobe />
                </a>
              </div>
              <p className="text-sm text-yellow-600 mt-4">
                Note: It may take up to 60 seconds for the site to become accessible at this URL.
              </p>
            </div>
            <div className="items-center px-4 py-3">
              <button
                id="ok-btn"
                className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                onClick={() => setShowPublishModal(false)}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async function handleDescriptionSubmit() {
    try {
      const token = await getValidToken();
      await axios.post(
        `${process.env.REACT_APP_USER_API_URL}/agents/${agentId}/files/description`,
        { 
          path: `${currentPath}${selectedFile.name}${selectedFile.isFolder ? '/' : ''}`,
          description: fileDescription 
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setShowDescriptionModal(false);
      fetchFiles(currentPath); // Refresh the file list
    } catch (err) {
      console.error('Error updating file/folder description:', err);
    }
  }

  function openDescriptionModal(file) {
    setSelectedFile(file);
    setFileDescription(file.description || '');
    setShowDescriptionModal(true);
  }

  function renderDescriptionModal() {
    if (!showDescriptionModal) return null;

    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="description-modal">
        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            {selectedFile.description ? 'Edit' : 'Add'} Description for {selectedFile.name}
          </h3>
          <textarea
            value={fileDescription}
            onChange={(e) => setFileDescription(e.target.value)}
            className="w-full h-32 p-2 border rounded"
            placeholder="Enter file description..."
          />
          <div className="mt-4 flex justify-end">
            <button
              onClick={() => setShowDescriptionModal(false)}
              className="mr-2 px-4 py-2 bg-gray-300 text-gray-800 rounded"
            >
              Cancel
            </button>
            <button
              onClick={handleDescriptionSubmit}
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }

  function renderFileList(files) {
    return (
      <ul className="space-y-2">
        {files.map(file => (
          <li key={file.name} className="flex items-center justify-between bg-gray-100 p-2 rounded">
            <span className="flex items-center cursor-pointer">
              {file.isFolder ? (
                <FaFolder className={`mr-2 ${file.isPublished ? 'text-green-500' : ''}`} />
              ) : (
                <FaFile className="mr-2" />
              )}
              <span 
                onClick={() => file.isFolder ? handleFolderClick(file.name) : null}
                className={file.isFolder ? "cursor-pointer" : ""}
              >
                {file.name}
              </span>
              {file.description && (
                <span className="ml-2 text-sm text-gray-500 italic">
                  {file.description.length > 20 ? file.description.substring(0, 20) + '...' : file.description}
                </span>
              )}
            </span>
            <div>
              <button 
                onClick={() => openDescriptionModal(file)} 
                className="text-purple-500 mr-2 hover:text-purple-700"
                title={file.description ? "Edit Description" : "Add Description"}
              >
                <FaInfoCircle />
              </button>
              {file.isFolder ? (
                <button 
                  onClick={() => handlePublishClick(`${currentPath}${file.name}`)}
                  className={`mr-2 ${file.isPublished ? 'text-green-500' : 'text-blue-500'} hover:text-opacity-70`}
                  title={file.isPublished ? 'View Published Site' : 'Publish'}
                >
                  <FaGlobe />
                </button>
              ) : (
                <>
                  <button 
                    onClick={() => handlePreviewFile(`${currentPath}${file.name}`)} 
                    className="text-green-500 mr-2 hover:text-green-700"
                    title="Preview"
                  >
                    <FaEye />
                  </button>
                  <button 
                    onClick={() => downloadFile(`${currentPath}${file.name}`)} 
                    className="text-blue-500 mr-2 hover:text-blue-700"
                    disabled={fileActions[file.name]}
                    title={fileActions[file.name]?.action === 'downloading' 
                      ? `Downloading... ${fileActions[file.name].progress}%` 
                      : 'Download'}
                  >
                    <FaDownload />
                  </button>
                </>
              )}
              <button 
                onClick={() => deleteFile(`${currentPath}${file.name}`)} 
                className="text-red-500 hover:text-red-700"
                disabled={fileActions[file.name]}
                title={fileActions[file.name]?.action === 'deleting' 
                  ? 'Deleting...' 
                  : 'Delete'}
              >
                <FaTrash />
              </button>
            </div>
          </li>
        ))}
      </ul>
    );
  }

  if (error) return <div>{error}</div>;

  return (
    <div className="bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Agent Files</h2>
      
      <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6">
        <p className="text-sm text-blue-700">
          <span className="font-semibold">How it works:</span> Your agent can access, create, and update these files. 
          This allows the agent to enhance its knowledge and capabilities during conversations.
        </p>
        <ul className="mt-2 list-disc list-inside text-sm text-blue-600">
          <li>Upload new files to provide additional information</li>
          <li>Download existing files to review the agent's knowledge</li>
          <li>Delete files that are no longer relevant</li>
          <li>The agent may create or modify files during interactions</li>
        </ul>
      </div>
      
      <div className="flex items-center mb-4 bg-gray-100 p-2 rounded">
        <button 
          onClick={() => document.querySelector('input[type="file"]').click()}
          className="bg-blue-500 text-white p-2 rounded mr-2 flex items-center"
          disabled={loading}
          title="Upload Files or Folders"
        >
          <FaUpload className="mr-2" />
          <span>Upload</span>
        </button>
        <button 
          onClick={() => setShowNewFolderInput(true)} 
          className="bg-yellow-500 text-white p-2 rounded mr-2 flex items-center"
          disabled={loading}
          title="New Folder"
        >
          <FaPlus className="mr-2" />
          <span>New Folder</span>
        </button>
        {showNewFolderInput && (
          <>
            <input
              type="text"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="Enter folder name"
              className="border rounded px-2 py-1 mr-2"
            />
            <button 
              onClick={createFolder}
              className="bg-blue-500 text-white px-4 py-2 rounded flex items-center"
              disabled={loading}
            >
              <FaPlus className="mr-2" />
              <span>Create</span>
            </button>
          </>
        )}
      </div>

      <div className="border-2 border-gray-200 rounded-lg bg-gray-50 p-4 mb-4">
        {renderBreadcrumbs()}

        <div className="relative min-h-[200px]">
          {loading && (
            <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-70 z-10">
              <div className="spinner"></div>
              <span className="ml-2">Loading files...</span>
            </div>
          )}

          {!loading && (
            files.length > 0 ? renderFileList(files) : (
              <p className="text-gray-600">No files or folders found in this directory. Upload files, create folders, or let the agent create new ones to give it access to additional information.</p>
            )
          )}
        </div>
      </div>

      <div {...getRootProps()} className={`p-4 border-2 border-dashed rounded-lg ${isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}`}>
        <input {...getInputProps()} multiple />
        {isDragActive ? (
          <p className="text-center text-blue-500">Drop the files or folders here ...</p>
        ) : (
          <p className="text-center text-gray-500">Drag 'n' drop files or folders here</p>
        )}
      </div>
      
      {isUploading && (
        <div className="mt-2 mb-4">
          <div className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div 
              className="bg-blue-600 h-2.5 rounded-full" 
              style={{width: `${uploadProgress}%`}}
            ></div>
          </div>
          <p className="text-sm text-gray-500 mt-1">{uploadProgress}% Uploaded</p>
        </div>
      )}
      
      {previewFile && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg max-w-3xl max-h-[90vh] overflow-auto">
            <h3 className="text-xl font-bold mb-2">{previewFile.name}</h3>
            {previewFile.type.startsWith('image/') ? (
              <img src={previewFile.url} alt={previewFile.name} className="max-w-full" />
            ) : (
              <iframe src={previewFile.url} title={previewFile.name} className="w-full h-[70vh]" />
            )}
            <button 
              onClick={closePreview}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
            >
              Close Preview
            </button>
          </div>
        </div>
      )}
      {renderPublishModal()}
      {renderDescriptionModal()}
    </div>
  );
}

export default AgentFiles;